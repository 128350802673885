<template>

  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <products-list-add-new
      :is-add-new-products-sidebar-active.sync="isAddNewProductsSidebarActive"
      @refetch-data="refetchProductsData"
    />

    <template>

      <b-row>

        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >

          <template>

            <!-- Table Container Card -->
            <b-card
              no-body
            >

              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Entries</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                  <!-- Add -->
                  <b-col
                    class="d-flex align-items-center justify-content-end"
                    cols="12"
                    md="6"
                  >
                    <b-button
                      v-if="$can('create', 'product')"
                      variant="primary"
                      @click="isAddNewProductsSidebarActive = true"
                    >
                      Toevoegen
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-table
                ref="refProductsListTable"
                :items="fetchProducts"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                show-empty
                empty-text="Geen overeenkomende records gevonden"
                class="position-relative products-table"
              >

                <!-- Column: Id -->
                <template #cell(id)="data">
                  <b-link
                    :to="{ name: 'apps-products-edit', params: { id: data.item.id }}"
                    class="font-weight-bold"
                  >
                    #{{ data.value }}
                  </b-link>
                </template>

                <!-- Column: name -->
                <template #cell(name)="data">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="data.item.avatar"
                        :text="avatarText(data.item.name)"
                        :to="{ name: 'apps-products-edit', params: { id: data.item.id } }"
                      />
                    </template>
                    <b-link
                      :to="{ name: 'apps-products-edit', params: { id: data.item.id } }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ data.item.name }}
                    </b-link>
                    <!--                    <div class="text-muted">{{ data.item.createTimestamp }}</div>-->
                  </b-media>
                </template>

                <template #cell(createTimestamp)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{formatDateTime(data.value)}}</span>
                  </div>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">

                  <div class="text-nowrap">
                    <b-link
                      :to="{ name: 'apps-products-edit', params: { id: data.item.id }}"
                      :id="`product-view-action-row-${data.item.id}`"
                    >
                      <feather-icon
                        :id="`product-row-${data.item.id}-preview-icon`"
                        class="cursor-pointer mx-1"
                        icon="EyeIcon"
                        size="16"
                      />
                    </b-link>
                    <b-tooltip
                        :target="`product-view-action-row-${data.item.id}`"
                        placement="top"
                    >
                      <p class="mb-0">
                        Aanpassen
                      </p>
                    </b-tooltip>
                    <feather-icon
                      v-if="$can('update', 'product')"
                      :id="`product-row-${data.item.id}-delete-icon`"
                      class="cursor-pointer"
                      size="16"
                      icon="TrashIcon"
                      @click="confirmProductsDeleteText(data.item.id)"
                    />
                    <b-tooltip
                        v-if="$can('update', 'product')"
                        :target="`product-row-${data.item.id}-delete-icon`"
                        placement="top"
                    >
                      <p class="mb-0">
                        Verwijderen
                      </p>
                    </b-tooltip>
                  </div>
                </template>

              </b-table>
            </b-card>

          </template>

        </b-col>

        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        />

      </b-row>

    </template>

  </b-overlay>

</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BLink,
  BMedia,
  BAvatar, BOverlay, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { formatDateTime } from '@core/utils/utils'
import useProductsList from '@/views/pages/products/useProductsList'
import productsStoreModule from '@/views/pages/products/productsStoreModule'
import ProductsListAddNew from '@/views/pages/products/products-add/ProductsListAddNew.vue'

export default {
  components: {
    ProductsListAddNew,
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    vSelect,
    BOverlay,
    BTooltip,
  },
  methods: {
    confirmProductsDeleteText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder het!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('apps-products-list/deleteProducts', { id })
            .then(() => {
              this.refetchProductsData()
              this.$swal({
                icon: 'success',
                title: 'Verwijderd!',
                text: 'Het product is verwijderd.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'apps-products-list'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, productsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const isAddNewProductsSidebarActive = ref(false)

    const {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalCategories,
      dataMeta,
      perPageOptions,
      sortBy,
      refProductsListTable,
      refetchProductsData,
      isSortDirDesc,
      showOverlay,
    } = useProductsList()

    return {
      isAddNewProductsSidebarActive,
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalCategories,
      dataMeta,
      perPageOptions,
      sortBy,
      refProductsListTable,
      refetchProductsData,
      avatarText,
      isSortDirDesc,
      showOverlay,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }

  .products-table{
    .description{
      width:30%;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
